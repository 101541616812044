/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $.fn.equalHeights = function() {
    var maxHeight = 0,
        $this = $(this);

    $this.each( function() {
      var height = $(this).innerHeight();

      if ( height > maxHeight ) { maxHeight = height; }
    });

    return $this.css('height', maxHeight);
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        if ($(window).width() > 768 ) {
          //wysokosc itemow w menu identyczna
          $(".cd-secondary-nav > li").equalHeights();
        }else{
          $(".cd-secondary-nav > li").height('auto');
        }

        $(window).resize(function () {
          if ($(window).width() > 768 ) {
            //wysokosc itemow w menu identyczna
            $(".cd-secondary-nav > li").equalHeights();
          }else{
            $(".cd-secondary-nav > li").height('auto');
          }
        });

       /* fluidvids.init({
          selector: ['iframe'],
          players: ['www.youtube.com', 'player.vimeo.com']
        });*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        if ($(window).width() > 768 ) {

          //desktop & tablets
          $("#mainCarousel").height($(window).height()*0.7 - $(".cd-main-header").height());
          $("#mainCarousel .item").height($(window).height()*0.7 - $(".cd-main-header").height());


          $(window).resize(function () {
            $("#mainCarousel").height($(window).height()*0.7 - $(".cd-main-header").height());
            $("#mainCarousel .item").height($(window).height()*0.7 - $(".cd-main-header").height());
          });





        }else{
          //mobile
          $("#mainCarousel").height(350);
          $("#mainCarousel .item").height(350);

          $(window).resize(function () {
            $("#mainCarousel").height(350);
            $("#mainCarousel .item").height(350);

          });
        }

          $(window).on("load",function(){
              $(".content").mCustomScrollbar({
                  axis:"x",
                  theme:"dark-thick",
                  advanced:{
                      autoExpandHorizontalScroll:true
                  }
              });
          });




      /*  var options = {
          horizontal: 1,
          itemNav: 'basic',
          speed: 300,
          mouseDragging: 1,
          touchDragging: 1
        };
        var frame = new Sly('#frame', options).init();*/











       /* $("#rellist22").owlCarousel({

          autoPlay: 3000, //Set AutoPlay to 3 seconds

          items : 2,
          itemsDesktop : [1199,3],
          itemsDesktopSmall : [979,3]

        });*/

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'zarzad': {
      init: function() {
        $('.cimg-with-caption .thumbnail').addClass("sh");

        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

(function($){

})(jQuery);

jQuery(document).ready(function($){

  $('.grid').masonry({
    // options
    itemSelector: '.grid-item',
    //columnWidth: 200
  });


  if ( $( ".realization-gallery" ).length ) {

    var
        activityIndicatorOn = function () {
          $('<div id="imagelightbox-loading"><div></div></div>').appendTo('body');
        },
        activityIndicatorOff = function () {
          $('#imagelightbox-loading').remove();
        },
        closeButtonOn = function (instance) {
          $('<button type="button" id="imagelightbox-close" title="Close"></button>').appendTo('body').on('click touchend', function () {
            $(this).remove();
            instance.quitImageLightbox();
            return false;
          });
        },
        closeButtonOff = function () {
          $('#imagelightbox-close').remove();
        },
        overlayOn = function()
        {
          $( '<div id="imagelightbox-overlay"></div>' ).appendTo( 'body' );
        },
        overlayOff = function()
        {
          $( '#imagelightbox-overlay' ).remove();
        };


    var instanceC = $('.realization-gallery .thumb a').imageLightbox(
        {
          quitOnDocClick: false,
          onStart: function () {
            overlayOn();
            closeButtonOn(instanceC);
          },
          onEnd: function () {
            overlayOff();
            closeButtonOff();
            activityIndicatorOff();
          },
          onLoadStart: function () {
            activityIndicatorOn();
          },
          onLoadEnd: function () {
            activityIndicatorOff();
          }
        });

  }


  //if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
  var MqL = 1170;
  //move nav element position according to window width
  moveNavigation();
  $(window).on('resize', function(){
    (!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation);
  });

  //mobile - open lateral menu clicking on the menu icon
  $('.cd-nav-trigger').on('click', function(event){
    event.preventDefault();
    if( $('.cd-main-content').hasClass('nav-is-visible') ) {
      closeNav();
      $('.cd-overlay').removeClass('is-visible');
    } else {
      $(this).addClass('nav-is-visible');
      $('.cd-primary-nav').addClass('nav-is-visible');
      $('.cd-main-header').addClass('nav-is-visible');
      $('.cd-main-content').addClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
        $('body').addClass('overflow-hidden');
      });
      toggleSearch('close');
      $('.cd-overlay').addClass('is-visible');
    }
  });

  //open search form
  $('.cd-search-trigger').on('click', function(event){
    event.preventDefault();
    toggleSearch();
    closeNav();
  });

  //close lateral menu on mobile
  $('.cd-overlay').on('swiperight', function(){
    if($('.cd-primary-nav').hasClass('nav-is-visible')) {
      closeNav();
      $('.cd-overlay').removeClass('is-visible');
    }
  });
  $('.nav-on-left .cd-overlay').on('swipeleft', function(){
    if($('.cd-primary-nav').hasClass('nav-is-visible')) {
      closeNav();
      $('.cd-overlay').removeClass('is-visible');
    }
  });
  $('.cd-overlay').on('click', function(){
    closeNav();
    toggleSearch('close')
    $('.cd-overlay').removeClass('is-visible');
  });


  //prevent default clicking on direct children of .cd-primary-nav
  $('.cd-primary-nav').children('.has-children').children('a').on('click', function(event){
    event.preventDefault();
  });
  //open submenu
  $('.has-children').children('a').on('click', function(event){
    if( !checkWindowWidth() ) event.preventDefault();
    var selected = $(this);
    if( selected.next('ul').hasClass('is-hidden') ) {
      //desktop version only
      selected.addClass('selected').next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('moves-out');
      selected.parent('.has-children').siblings('.has-children').children('ul').addClass('is-hidden').end().children('a').removeClass('selected');
      $('.cd-overlay').addClass('is-visible');
    } else {
      selected.removeClass('selected').next('ul').addClass('is-hidden').end().parent('.has-children').parent('ul').removeClass('moves-out');
      $('.cd-overlay').removeClass('is-visible');
    }
    toggleSearch('close');
  });

  $('.cd-primary-nav .has-children > .text-uppercase').hover(function () {
      if ($(window).width() > MqL ){
          if (!checkWindowWidth()) event.preventDefault();
          var selected = $(this);
          if (selected.next('ul').hasClass('is-hidden')) {
            //desktop version only
            selected.addClass('selected').next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('moves-out');
            selected.parent('.has-children').siblings('.has-children').children('ul').addClass('is-hidden').end().children('a').removeClass('selected');
            $('.cd-overlay').addClass('is-visible');
          } else {
            selected.removeClass('selected').next('ul').addClass('is-hidden').end().parent('.has-children').parent('ul').removeClass('moves-out');
            $('.cd-overlay').removeClass('is-visible');
          }
          toggleSearch('close');
        }
      },
      function () {
        //stuff to do on mouse leave
        //closeNav();
      });
  $('.cd-secondary-nav, .cd-nav-icons').hover(function () {

      },
      function () {
        //stuff to do on mouse leave
        if ($(window).width() > MqL ){
          closeNav();
          $('.cd-overlay').removeClass('is-visible');
        }
      });
  //submenu items - go back link
  $('.go-back').on('click', function(){
    $(this).parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('moves-out');
  });

  function closeNav() {
    $('.cd-nav-trigger').removeClass('nav-is-visible');
    $('.cd-main-header').removeClass('nav-is-visible');
    $('.cd-primary-nav').removeClass('nav-is-visible');
    $('.has-children ul').addClass('is-hidden');
    $('.has-children a').removeClass('selected');
    $('.moves-out').removeClass('moves-out');
    $('.cd-main-content').removeClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
      $('body').removeClass('overflow-hidden');
    });
  }

  function toggleSearch(type) {
    if(type=="close") {
      //close serach
      $('.cd-search').removeClass('is-visible');
      $('.cd-search-trigger').removeClass('search-is-visible');
      $('.cd-overlay').removeClass('search-is-visible');
    } else {
      //toggle search visibility
      $('.cd-search').toggleClass('is-visible');
      $('.cd-search-trigger').toggleClass('search-is-visible');
      $('.cd-overlay').toggleClass('search-is-visible');
      if($(window).width() > MqL && $('.cd-search').hasClass('is-visible')) $('.cd-search').find('input[type="search"]').focus();
      ($('.cd-search').hasClass('is-visible')) ? $('.cd-overlay').addClass('is-visible') : $('.cd-overlay').removeClass('is-visible') ;
    }
  }

  function checkWindowWidth() {
    //check window width (scrollbar included)
    var e = window,
        a = 'inner';
    if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    if ( e[ a+'Width' ] >= MqL ) {
      return true;
    } else {
      return false;
    }
  }

  function moveNavigation(){
    var navigation = $('.cd-nav');
    var desktop = checkWindowWidth();
    if ( desktop ) {
      navigation.detach();
      navigation.insertBefore('.cd-header-buttons');
    } else {
      navigation.detach();
      navigation.insertAfter('.cd-main-content');
    }
  }
});

jQuery(document).ready(function($){
  // browser window scroll (in pixels) after which the "back to top" link is shown
  var offset = 300,
      //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
      offset_opacity = 1200,
      //duration of the top scrolling animation (in ms)
      scroll_top_duration = 700,
      //grab the "back to top" link
      $back_to_top = $('.cd-top');

  //hide or show the "back to top" link
  $(window).scroll(function(){
    ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
    if( $(this).scrollTop() > offset_opacity ) {
      $back_to_top.addClass('cd-fade-out');
    }
  });

  //smooth scroll to top
  $back_to_top.on('click', function(event){
    event.preventDefault();
    $('body,html').animate({
          scrollTop: 0 ,
        }, scroll_top_duration
    );
  });

});